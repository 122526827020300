import { graphql, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import React, { useRef, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import DefaultHeaderMenu from '../../components/defaultHeaderMenu/defaultHeaderMenu';
import BootstrapNavbar from '../../components/navigationBar/mainMenu/navigationBar';
import Footer from '../../components/footer/footer';
import BreadcrumbsBlock from '../../common/breadcrumbsBlock/breadcrumbsBlock';
import * as Styled from './searchPageTemplateStyle';
import SearchPage from './SearchPage';
import LocationsIcon from '../../images/icon-view-locations-search.gif';
import BackToTopScroll from '../../components/backToTopScroll/backToTopScroll';
import GlobalStyles from '../../styles/globalStyles';
import TopBanner from '../../common/topBanner/topBanner';
import { addTrailingSlash, isExternalUrlhref } from '../../utils';

const SearchPageTemplate = ({ data, location }) => {
  const params = new URLSearchParams(location.search.slice(1));
  const q = params.get('q') || '';
  const inputEl = useRef(null);
  const [query, setQuery] = React.useState(q);
  const breadcrumbsTitle = 'Search';
  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = () => {
    // `inputEl.current` points to the mounted search input element
    const q = inputEl.current.value;
    if (q) {
      navigate(`/search/?q=${q}`);
    }
  };
  const handleKeyUp = (event) => {
    if (event.keyCode === 13 || event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    setQuery(params.get('q') || '');
  }, [location]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fas Gas Plus :: Search</title>
        <meta name="description" content></meta>
      </Helmet>
      <GlobalStyles />
      <DefaultHeaderMenu />
      <BootstrapNavbar />
      <TopBanner path={location.pathname} />
      <BreadcrumbsBlock
        path={location.pathname}
        breadcrumbsTitle={breadcrumbsTitle}
      />
      <Styled.AboutContainer className="p-0">
        <Styled.LeftContainer xs={12} lg={4}></Styled.LeftContainer>
        <Styled.RightContainer xs={12} lg={8}>
          <Styled.SearchTitle>Search</Styled.SearchTitle>
          <Row className="g-0">
            <Col xs md={7} lg={6}>
              <Styled.InputSearch
                ref={inputEl}
                name="query"
                type="text"
                value={query}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
              />
              <Styled.InputSubmit
                name="submit"
                type="submit"
                value="Search"
                onClick={handleSubmit}
              />
            </Col>
            <Col xs={12} md={5} lg={6}>
              <Styled.LinkStyle
                href={
                  isExternalUrlhref('/station-directory')
                    ? '/station-directory'
                    : addTrailingSlash(
                        process.env.GATSBY_DOMAIN_URL + '/station-directory'
                      )
                }
              >
                Looking for a branch?
                <br />
                View our station directory
                <Styled.LocationImage src={LocationsIcon} />
              </Styled.LinkStyle>
            </Col>
          </Row>
          <SearchPage data={data} location={location} />
        </Styled.RightContainer>
      </Styled.AboutContainer>
      <Footer showProducts={true} />
      <BackToTopScroll />
    </>
  );
};

export default SearchPageTemplate;

export const pageQuery = graphql`
  query SearchPageMyQuery {
    site {
      siteMetadata {
        title
      }
    }
    LunrIndex
  }
`;
