import React from 'react';
import * as Styled from './searchPageTemplateStyle';
import Highlighter from 'react-highlight-words';
import { addTrailingSlash } from '../../utils';
import { getSearchData } from '../../api/searchApi';

const SearchPage = ({ location }) => {
  const params = new URLSearchParams(location?.search);
  const q = params.get('q') || '';

  const [results, setResults] = React.useState(0);

  const search = () => {
    getSearchData(q)
      .then(({ data }) => {
        const visitedSlugs = new Set();

        const filteredHit = data?.hits?.hit?.filter((obj) => {
          if (visitedSlugs?.has(obj?.fields?.slug)) {
            return false;
          } else {
            visitedSlugs.add(obj?.fields?.slug);
            return true;
          }
        });
        setResults(filteredHit);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (q) {
      search();
    }
  }, [location]);

  const getDataWithEllipsis = (content) => {
    const indexOfSearchInContent = content
      ?.toLowerCase()
      ?.indexOf(q?.toLowerCase());

    if (indexOfSearchInContent === -1) {
      return content?.substr(0, 250).concat(content?.length > 250 ? '...' : '');
    }
    if (content.length - (indexOfSearchInContent + q.length) < 250) {
      return '...' + content?.substr(-250);
    }
    if (content.length - (indexOfSearchInContent + q.length) > 250) {
      return indexOfSearchInContent > 3
        ? '...' + content?.substr(indexOfSearchInContent, 250) + '...'
        : content?.substr(indexOfSearchInContent, 250) + '...';
    }
    const data = content?.substr(
      Math.abs(indexOfSearchInContent),
      250 + indexOfSearchInContent
    );

    return data;
  };

  return (
    <>
      {q ? (
        results ? (
          results?.length > 0 ? (
            results?.map(({ fields }, index) => (
              <React.Fragment key={index}>
                <a
                  href={
                    process.env.GATSBY_DOMAIN_URL +
                    addTrailingSlash(fields?.slug)
                  }
                >
                  <h2>
                    <Highlighter
                      highlightClassName="highlightText"
                      searchWords={[q]}
                      autoEscape={true}
                      textToHighlight={fields?.title}
                    />
                  </h2>
                </a>
                <p>
                  {fields?.content_subtext
                    ?.toLowerCase()
                    ?.search(q?.toLowerCase()) >= 0 && (
                    <Highlighter
                      highlightClassName="highlightText"
                      searchWords={[q]}
                      autoEscape={true}
                      textToHighlight={fields?.content_subtext}
                    />
                  )}
                  {fields?.content_header
                    ?.toLowerCase()
                    ?.search(q?.toLowerCase()) >= 0 && (
                    <Highlighter
                      highlightClassName="highlightText"
                      searchWords={[q]}
                      autoEscape={true}
                      textToHighlight={fields?.content_header}
                    />
                  )}
                  {fields?.content_raw && (
                    <Highlighter
                      highlightClassName="highlightText"
                      searchWords={[q]}
                      autoEscape={true}
                      textToHighlight={getDataWithEllipsis(fields?.content_raw)}
                    />
                  )}
                </p>
                <Styled.SearchAnchor
                  href={
                    process.env.GATSBY_DOMAIN_URL +
                    addTrailingSlash(fields?.slug)
                  }
                >
                  <Highlighter
                    highlightClassName="highlightText"
                    searchWords={[q]}
                    autoEscape={true}
                    textToHighlight={
                      process.env.GATSBY_DOMAIN_URL +
                      addTrailingSlash(fields?.slug)
                    }
                  />
                </Styled.SearchAnchor>
                <br />
              </React.Fragment>
            ))
          ) : (
            <Styled.NoResults>
              There were no results found. Please try another keyword or phrase.
            </Styled.NoResults>
          )
        ) : (
          <Styled.NoResults>Loading...</Styled.NoResults>
        )
      ) : (
        <Styled.NoResults>Please Enter a keyword to search</Styled.NoResults>
      )}
    </>
  );
};
export default SearchPage;
